import React from 'react'
import { Col, Form, Row } from 'react-bootstrap'

export default function ServiceReportDetialsForm({ register, errors }) {
    return (
        <React.Fragment>
            <Form.Control {...register('edit_details.edit_count', {})} style={{ display:'none' }} />
            <Row>
                <Form.Group as={Col}>
                    <Form.Label>Descripción Edición</Form.Label>
                    <Form.Control 
                        {...register('edit_details.edit_label', {})}
                        placeholder='Escriba aquí...'
                    />
                </Form.Group>
                <Form.Group as={Col}>
                    <Form.Label>Fecha Edición</Form.Label>
                    <Form.Control 
                        type='date'
                        {...register('edit_details.edit_date', {})}
                    />
                </Form.Group>
            </Row>
            <Row>
                <Form.Group as={Col}>
                    <Form.Label>Razón Social</Form.Label>
                    <Form.Control 
                        {...register('edit_details.company_name', {})}
                        placeholder='Escriba aquí...'
                    />
                </Form.Group>
                <Form.Group as={Col}>
                    <Form.Label>NIT</Form.Label>
                    <Form.Control 
                        {...register('edit_details.company_document', {})}
                        placeholder='Escriba aquí...'
                    />
                </Form.Group>
            </Row>
            <Row>
                <Form.Group as={Col}>
                    <Form.Label>Representante Legal</Form.Label>
                    <Form.Control 
                        {...register('edit_details.legal_owner_name', {})}
                        placeholder='Escriba aquí...'
                    />
                </Form.Group>
                <Form.Group as={Col}>
                    <Form.Label>Nombre de Instalación</Form.Label>
                    <Form.Control 
                        {...register('edit_details.branch_name', {})}
                        placeholder='Escriba aquí...'
                    />
                </Form.Group>
            </Row>
            <Row>
                <Form.Group as={Col}>
                    <Form.Label>Correo electrónico</Form.Label>
                    <Form.Control 
                        {...register('edit_details.branch_email', {})}
                        placeholder='Escriba aquí...'
                    />
                </Form.Group>
                <Form.Group as={Col}>
                    <Form.Label>Dirección</Form.Label>
                    <Form.Control 
                        {...register('edit_details.branch_address_line', {})}
                        placeholder='Escriba aquí...'
                    />
                </Form.Group>
            </Row>
            <Row>
                <Form.Group as={Col}>
                    <Form.Label>Departamento</Form.Label>
                    <Form.Control 
                        {...register('edit_details.branch_address_state', {})}
                        placeholder='Escriba aquí...'
                    />
                </Form.Group>
                <Form.Group as={Col}>
                    <Form.Label>Ciudad</Form.Label>
                    <Form.Control 
                        {...register('edit_details.branch_address_city', {})}
                        placeholder='Escriba aquí...'
                    />
                </Form.Group>
            </Row>
            <Form.Group className='mt-10 mb-10'>
                <Form.Label>Contar como módificación</Form.Label>
                <Form.Control
                    as="select"
                    {...register('count_as_modification', {})}
                >
                    <option value="">No</option>
                    <option>Sí</option>
                </Form.Control>
            </Form.Group>
        </React.Fragment>
    )
}