import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { Form } from 'react-bootstrap'
import { message } from 'antd'

import ReportResultsDetailsForm from '../forms/ReportResultsDetailsForm'

import { updateServiceReport } from '../../services'
import { handleError } from '../../../../helpers'

export default function ReportResultsDetailsModal({ visible, onClose, serviceReport, reload }) {
    const [loading, setLoading] = useState(false)
	const { register, handleSubmit, formState: { errors } } = useForm({
        defaultValues: {
            report_details: serviceReport.report_details
        }
    })

	const onSubmit = values => {
        setLoading(true)
		updateServiceReport(serviceReport.id, values)
			.then(res => {
				message.success(res.data.message)
				reload()
			})
			.catch(error => handleError(error))
	}

	return (
		<React.Fragment>
			<Modal isOpen={visible}>
				<ModalHeader toggle={onClose}>Resultados y Observaciones</ModalHeader>
				<ModalBody>
					<Form onSubmit={handleSubmit(onSubmit)}>
						<ReportResultsDetailsForm 
                            register={register}
                            errors={errors}
							serviceReport={serviceReport}
                        />
						<Button color="primary" type="submit" disabled={loading}>
                            Actualizar Resultado Final
                        </Button>
					</Form>
				</ModalBody>
			</Modal>
		</React.Fragment>
	)
}