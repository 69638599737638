import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'
import { Form } from 'react-bootstrap'
import { Button, Modal, ModalBody, ModalHeader, Spinner } from 'reactstrap'
import { message } from 'antd'

import { offlineUpdateReview } from '../../Services/actions'
import { queueRequest } from '../../Offline/actions'
import { updateServiceReview } from '../services'
import { handleError } from '../../../helpers'

export default function ReviewSecurityModal({ visible, onClose, serviceReview, serviceId, businessScope, reload }) {
	const { isConnected } = useSelector(state => state.offline)
	const { register, handleSubmit } = useForm({})
	const dispatch = useDispatch()

	const onSubmit = values => {
		if(isConnected){
			updateServiceReview(serviceReview.id, {
				...values,
				review_specs: {
					...serviceReview.review_specs,
					...values.review_specs
				}
			})
				.then(res => {
					message.success(res.data.message)
					onClose()
					reload()
				})
				.catch(error => handleError(error))
		} else {
			dispatch(offlineUpdateReview(values, serviceId))
			dispatch(queueRequest({url: `/service_reviews/${serviceReview.id}`, data: values}))
			message.info('Actualización en espera de internet')
			onClose()
		}
	}

	return (
		<React.Fragment>
			<Modal isOpen={visible}>
				<ModalHeader toggle={onClose}>Información de Seguridad</ModalHeader>
				<ModalBody>
					{ serviceReview ? (

						<Form onSubmit={handleSubmit(onSubmit)}>
							<Form.Group>
								<Form.Label>1. ¿Conoce las acciones que se deben adelantar en caso de emergencia?</Form.Label>
								<Form.Check 
									{...register('review_specs.has_security_actions')}
									label="Si"
									value="1"
									type="radio"
									inline
									defaultChecked={parseInt(serviceReview.review_specs.has_security_actions) ? true : false}
								/>
								<Form.Check 
									{...register('review_specs.has_security_actions')}
									label="No"
									value="0"
									type="radio"
									inline
									defaultChecked={parseInt(serviceReview.review_specs.has_security_actions) ? false : true}
								/>
								<Form.Control 
									as="textarea"
									{...register('review_specs.security_actions_comments')}
									defaultValue={serviceReview && serviceReview.review_specs.security_actions_comments}
									placeholder='Observaciones'
									className='mt-10'
								/>
							</Form.Group>
							<Form.Group>
								<Form.Label>2. ¿Se cuenta con las condiciones necesarias para realizar la inspección de manera segura?</Form.Label>
								<Form.Check 
									{...register('review_specs.has_security_conditions')}
									label="Si"
									value="1"
									type="radio"
									inline
									defaultChecked={parseInt(serviceReview.review_specs.has_security_conditions) ? true : false}
								/>
								<Form.Check 
									{...register('review_specs.has_security_conditions')}
									label="No"
									value="0"
									type="radio"
									inline
									defaultChecked={parseInt(serviceReview.review_specs.has_security_conditions) ? false : true}
								/>
								<Form.Control 
									as="textarea"
									{...register('review_specs.security_conditions_comments')}
									defaultValue={serviceReview.review_specs.security_conditions_comments}
									placeholder='Observaciones'
									className='mt-10'
								/>
							</Form.Group>
							<Form.Group>
								<Form.Label>3. ¿Requiere de algún elemento adicional para realizar la inspección de forma segura?</Form.Label>
								<Form.Check 
									{...register('review_specs.has_security_additionals')}
									label="Si"
									value="1"
									type="radio"
									inline
									defaultChecked={parseInt(serviceReview.review_specs.has_security_additionals) ? true : false}
								/>
								<Form.Check 
									{...register('review_specs.has_security_additionals')}
									label="No"
									value="0"
									type="radio"
									inline
									defaultChecked={parseInt(serviceReview.review_specs.has_security_additionals) ? false : true}
								/>
								<Form.Control 
									as="textarea"
									{...register('review_specs.security_additionals_comments')}
									defaultValue={serviceReview.review_specs.security_additionals_comments}
									placeholder='Observaciones'
									className='mt-10'
								/>
							</Form.Group>
							{ businessScope === 'pruebas' && (
								<React.Fragment>
									<Form.Group>
										<Form.Label>4. ¿Existe soporte técnico con recomendación por parte del fabricante sobre el procedimiento de prueba que se debe utilizar?</Form.Label>
										<Form.Check 
											{...register('review_specs.has_tecnical_support')}
											label="Si"
											value="1"
											type="radio"
											inline
											defaultChecked={parseInt(serviceReview.review_specs.has_tecnical_support) ? true : false}
										/>
										<Form.Check 
											{...register('review_specs.has_tecnical_support')}
											label="No"
											value="0"
											type="radio"
											inline
											defaultChecked={parseInt(serviceReview.review_specs.has_tecnical_support) ? false : true}
										/>
										<Form.Control 
											as="textarea"
											{...register('review_specs.tecnical_support_comments')}
											defaultValue={serviceReview.review_specs.tecnical_support_comments}
											placeholder='Observaciones'
											className='mt-10'
										/>
									</Form.Group>
								</React.Fragment>
							)}
							<Button color="primary" type="submit">Actualizar Información</Button>
						</Form>
					) : <Spinner />}
				</ModalBody>
			</Modal>
		</React.Fragment>
	)
}