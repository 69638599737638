import React, { useState } from 'react'
import { Descriptions } from 'antd'
import moment from 'moment'

import EditServiceReportDetailsModal from './modals/EditServiceReportDetailsModal'

import { text } from '../../../helpers'

const { Item } = Descriptions

export default function ServiceReportDetails({ serviceReport, reload }) {
    const [showEditModal, setShowEditModal] = useState(false)

    const branch = serviceReport.service_review.service_plan.service_inspector.service.proposal.inquiry.branch_spec.branch

    const editValue = (attribute, novalue = '') => (
        (serviceReport.edit_details && serviceReport.edit_details[attribute]) ? serviceReport.edit_details[attribute] : novalue
    )
    
    return (
        <React.Fragment>
            <Descriptions bordered size="small" className='mt-15' column={2}>
				<Item label="Versión">
					{serviceReport.format_version.sku}
				</Item>
				<Item label="Fecha Vigencia">
					{moment(serviceReport.format_version.expire_at).format('DD-MMM-YYYY').toUpperCase()}
				</Item>
				<Item label="Edición">
                    Edición {editValue('edit_count', 1)}{". "}
                    {editValue('edit_label', 'Emisión del Informe')}{" "}
                    {editValue('edit_date')}
				</Item>
                <Item label="Razón Social">
                    {editValue('company_name', branch.company.name)}
				</Item>
                <Item label="NIT">
                    {editValue('company_document', branch.company.complete_document)}
				</Item>
                <Item label="Representante Legal">
                    {editValue('legal_owner_name', branch.company.legal_owner_name)}
				</Item>
                <Item label="Nombre de Instalación">
                    {editValue('branch_name', branch.name)}
				</Item>
                <Item label="Correo electrónico">
                    {editValue('branch_email', text(branch.email))}
				</Item>
                <Item label="Dirección">
                    {editValue('branch_address_line', branch.address_line)}
				</Item>
                <Item label="Departamento">
                    {editValue('branch_address_state', branch.address_state)}
				</Item>
                <Item label="Ciudad">
                    {editValue('branch_address_city', branch.address_city)}
				</Item>
			</Descriptions>
            <div className='text-right mt-15'>
                <p className='text-link' onClick={() => setShowEditModal(true)}>
                    Modificar Informe de Inspección
                </p>
            </div>
            { showEditModal && (
                <EditServiceReportDetailsModal
                    visible
                    onClose={() => setShowEditModal(false)}
                    reload={reload}
                    serviceReport={serviceReport}
                />
            )}
        </React.Fragment>
    )
}