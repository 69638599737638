import React from 'react'
import { Col, Row } from 'reactstrap'
import styled from 'styled-components'
import moment from 'moment'

import DocumentHeader from '../../../../components/DocumentHeader'
import logo from '../../../../assets/images/logo_dewar_onac.png'
import { is_positive, text } from '../../../../helpers'

export default function SERVICE_REPORT_TEMPLATE_PRU01({ serviceReport }) {

    const serviceReview = serviceReport.service_review
	const service = serviceReview.service_plan.service_inspector.service
    const inquiry = service.proposal.inquiry
    const branch = inquiry.branch_spec.branch

    const parseResults = reviewLogbook => {
        if(!reviewLogbook || !reviewLogbook.requirements_survey || !reviewLogbook.requirements_survey.item_0410 || !reviewLogbook.requirements_survey.item_0420) 
            return <em className='small text-muted'>Sin registro</em>

        if(reviewLogbook.requirements_survey.item_0410.includes('No') || reviewLogbook.requirements_survey.item_0420.includes('No'))
            return 'No Cumple'

        return 'Cumple'
    }

    const editValue = (attribute, novalue = '') => (
        (serviceReport.edit_details && serviceReport.edit_details[attribute]) ? serviceReport.edit_details[attribute] : novalue
    )

    return (
        <Container>
            <DocumentHeader
				title="INFORME DE INSPECCIÓN"
				version={serviceReport.format_version.sku}
				expireAt={serviceReport.format_version.expire_at}
				proposalSku={service.proposal.sku}
				logoSrc={logo}
			/>
            <table className='table dewar_template_table'>
                <tbody>
                    <tr>
                        <td colSpan={4} className='title text-left'>1. INFORMACIÓN GENERAL</td>
                    </tr>
                    <tr>
                        <td width="150px" className='subtitle2'>Fecha de acta:</td>
                        <td width="200px">{ moment(serviceReview.service_plan.inspected_at).format('DD-MMM-YYYY').toUpperCase() }</td>
                        <td width="170px" className='subtitle2'>Consecutivo de Informe:</td>
                        <td>{serviceReport.sku_formated}</td>
                    </tr>
                    <tr>
                        <td className='subtitle2'>Fecha de elaboración del informe:</td>
                        <td>{ moment(serviceReport.reported_at).format('DD-MMM-YYYY').toUpperCase() }</td>
                        <td colSpan={2} className='text-center'>
                            Edición {editValue('edit_count', 1)}{". "}
                            {editValue('edit_label', 'Emisión del Informe')}{" "}
                            {editValue('edit_date')}
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2} className='subtitle2'>Nombre del inspector que realizó el proceso:</td>
                        <td colSpan={2}>{ serviceReview.service_plan.service_inspector.user.name }</td>
                    </tr>
                    <tr>
                        <td colSpan={4}>El personal que realizó las pruebas de hermeticidad está debidamente capacitado, demuestra la competencia  y cuenta con la experiencia necesaria para este tipo de procedimiento. Así mismo ha realizado la declaración de imparcialidad, independencia y confidencialidad.</td>
                    </tr>
                </tbody>
            </table>
            <table className='table dewar_template_table'>
                <tbody>
                    <tr>
                        <td colSpan={4} className='title text-left'>2. INFORMACIÓN INSTALACIÓN</td>
                    </tr>
                    <tr>
                        <td width="150px" className='subtitle2'>Razón Social:</td>
                        <td>{editValue('company_name', branch.company.name)}</td>
                        <td width="150px" className='subtitle2'>NIT</td>
                        <td>{editValue('company_document', branch.company.complete_document)}</td>
                    </tr>
                    <tr>
                        <td className='subtitle2'>Representante Legal:</td>
                        <td colSpan={3}>{editValue('legal_owner_name', branch.company.legal_owner_name)}</td>
                    </tr>
                    <tr>
                        <td className='subtitle2'>Nombre de Instalación:</td>
                        <td colSpan={3}>{editValue('branch_name', branch.name)}</td>
                    </tr>
                    <tr>
                        <td className='subtitle2'>Correo electrónico</td>
                        <td>{editValue('branch_email', text(branch.email))}</td>
                        <td className='subtitle2'>Dirección</td>
                        <td>{editValue('branch_address_line', branch.address_line)}</td>
                    </tr>
                    <tr>
                        <td className='subtitle2'>Departamento</td>
                        <td>{editValue('branch_address_state', branch.address_state)}</td>
                        <td className='subtitle2'>Ciudad</td>
                        <td>{editValue('branch_address_city', branch.address_city)}</td>
                    </tr>
                    <tr>
                        <td className='subtitle2'>Teléfono Fijo</td>
                        <td>{text(branch.phone)}</td>
                        <td className='subtitle2'>Celular</td>
                        <td>{text(branch.mobile)}</td>
                    </tr>
                    <tr>
                        <td className='subtitle2'>Nombre y Apellido:</td>
                        <td>{inquiry.employee.name}</td>
                        <td className='subtitle2'>Cargo:</td>
                        <td>{inquiry.employee.position}</td>
                    </tr>
                </tbody>
            </table>
            <table className='table dewar_template_table'>
                <tbody>
                    <tr>
                        <td className='title text-left'>3. DESCRIPCIÓN DEL PROCEDIMIENTO DE INSPECCIÓN</td>
                    </tr>
                    <tr>
                        <td className='bg-light'>
                            Ensayo No Destructivo que permite detectar un caudal de fuga conforme a un procedimiento de ensayo dado. El ensayo tiene en cuenta la dilatación térmica del liquido almacenado, pérdidas por evaporación y efectos de cualquier otra variable, incluyendo el nivel de las aguas subterráneas. Se utiliza para determinar la presencia de fugas de combustible en tanques y tuberías. Dicha prueba puede determinar fugas con una certeza o probabilidad de detección del 95%, y un error máximo del 5%.
                            <br /><strong>Sistema de Almacenamiento:</strong> La prueba de hermeticidad de los tanques se realiza inyectando gas inerte (Nitrógeno) al tanque hasta alcanzar una presión final de prueba de 2.5 -3.0 PSI (+5% presión de trabajo del tanque en condiciones normales), verificando que ésta se mantiene estable durante por lo menos una (1) hora, dentro de los parámetros de medición y confiabilidad de los equipos utilizados. Si durante el proceso de inspección, se puede acceder a los Tubos de desfogue de forma segura, se verificará el funcionamiento como parte integral del sistema de almacenamiento.
                            <br /><strong>Sistema de conducción de combustible:</strong> La prueba de hermeticidad de las tuberías de entrada (llenado), desfogue  y de entrega de combustible que van desde la bomba sumergible o del tanque de combustible hasta los equipos dispensadores /surtidores se realiza inyectando gas inerte a la tubería, de acuerdo a indicaciones del fabricante o de acuerdo a la presión máxima de operación de la tuberia de acuerdo a la instalación, verificando que ésta se mantiene estable durante por lo menos una (1) horas.  En los sistemas de conducción donde existan valvulas de impacto, el funcionamiento de las mismas será verificado durante el desarrolla de la prueba de tubería.
                        </td>
                    </tr>
                </tbody>
            </table>
            <table className='table dewar_template_table'>
                <tbody>
                    <tr>
                        <td colSpan={5} className='title text-left'>4. EQUIPOS DE MEDICIÓN EMPLEADOS EN LA INSPECCIÓN</td>
                    </tr>
                    <tr>
                        <td className='bg-light' colSpan={5}>El sistema de prueba no destructivo FULL-TEST fue desarrollado con el propósito de certificar la hermeticidad de los tanques de almacenamiento de combustible. Está basado en un sistema de sensores de presión y nivel de alta precisión realizando un proceso análogo digital para el análisis de resultados.</td>
                    </tr>
                    <tr className='text-center'>
                        <td className='subtitle2'>Ítem</td>
                        <td className='subtitle2'>Equipo</td>
                        <td className='subtitle2'>Marca</td>
                        <td className='subtitle2'>Serial</td>
                        <td className='subtitle2'>Fecha de calibración / verificación</td>
                    </tr>
                    { serviceReview.review_equipments.length > 0 ? (
                        <React.Fragment>
                            { serviceReview.review_equipments.map( reviewEquipment => (
                                <tr key={reviewEquipment.id}>
                                    <td>{reviewEquipment.item}</td>
                                    <td>{reviewEquipment.name}</td>
                                    <td>{reviewEquipment.brand}</td>
                                    <td>{reviewEquipment.serial}</td>
                                    <td>{moment(reviewEquipment.verified_at).format('DD-MMM-YYYY').toUpperCase()}</td>
                                </tr>
                            )) }
                        </React.Fragment>
                    ) : (
                        <tr>
                            <td colSpan={5}>
                                <em>No se tiene equipos de medición asociados</em>
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
            <table className='table dewar_template_table'>
                <tbody>
                    <tr>
                        <td className='title text-left'>5. REGISTRO FOTOGRÁFICO DEL SERVICIO</td>
                    </tr>
                    <tr>
                        <td>
                            { serviceReport.report_evidence.length > 0 ? (
                                <Row className='m-0'>
                                    { serviceReport.report_evidence.map(evidence => evidence.attachment_uri && (
                                        <Col key={evidence.id} sm="4" style={{ height: '200px' }} className='bg-light text-center p-5'>
                                            <img alt="Evidencia Registrada" src={evidence.attachment_uri} width="100%" style={{ maxHeight: '200px', objectFit: 'contain' }} />
                                        </Col>
                                    )) }
                                </Row>
                            ) : (
                                <em>No se tiene registro fotográfico adjunto</em>
                            )}
                        </td>
                    </tr>
                </tbody>
            </table>
            <table className='table dewar_template_table'>
                <tbody>
                    <tr>
                        <td className='title text-left'>6. RESULTADOS DEL PROCESO</td>
                    </tr>
                    <tr>
                        <td className='title text-left'>6.1  RESULTADOS PRUEBA HERMETICIDAD TANQUES</td>
                    </tr>
                    <tr></tr>
                </tbody>
            </table>
            { serviceReview.review_logbooks.filter(r => r.spec_type === 'tank').map(reviewLogbook => (
                <table key={reviewLogbook.id} className='table dewar_template_table'>
                    <tbody>
                        <tr>
                            <td colSpan={2} className='title text-right'>Nº Tanque</td>
                            <td colSpan={2} className='title text-left'>{reviewLogbook.spec_number}</td>
                            <td colSpan={2} className='title text-right'>Fecha Inspección</td>
                            <td colSpan={2} className='title text-left'>{reviewLogbook.register_at}</td>
                        </tr>
                        <tr>
                            <td className='subtitle'>Identificación del Tanque</td>
                            <td className='subtitle'>Tipo Tanque</td>
                            <td className='subtitle'>Material</td>
                            <td className='subtitle'>Capacidad (gal)</td>
                            <td className='subtitle'>Producto</td>
                            <td className='subtitle'>Diámetro de tanque (m)</td>
                            <td className='subtitle'>Compatido</td>
                            <td className='subtitle'>Doble Contención</td>
                        </tr>
                        <tr>
                            <td>{text(reviewLogbook.spec_details.identificacion_tanque)}</td>
                            <td>{reviewLogbook.spec_category_slug}</td>
                            <td>{text(reviewLogbook.spec_details.material)}</td>
                            <td>{text(reviewLogbook.spec_details.capacidad)}</td>
                            <td>{reviewLogbook.product_other_name ? reviewLogbook.product_other_name : reviewLogbook.product_name}</td>
                            <td>{text(reviewLogbook.spec_details.diametro_tanque)}</td>
                            <td>{text(reviewLogbook.spec_details.bicompartido)}</td>
                            <td>{text(reviewLogbook.spec_details.doble_contencion)}</td>
                        </tr>
                        <tr>
                            <td className='subtitle'>Bypass ó Sifón</td>
                            <td className='subtitle'>Nivel de Llenado del Tanque (gal)</td>
                            <td className='subtitle'>Nivel de Llenado del Tanque (cm)</td>
                            <td className='subtitle'>Temperatura ºC</td>
                            <td className='subtitle'>Presión de la Prueba / PSI</td>
                            <td className='subtitle'>Resultados de la Prueba</td>
                            <td className='subtitle' colSpan={2}>Revisión y Decisión del Director Técnico</td>
                        </tr>
                        <tr>
                            <td>
                                {(is_positive(reviewLogbook.spec_details.bypass) || is_positive(reviewLogbook.spec_details.tiene_sifon)) ? 'Sí' : 'No'}
                            </td>
                            <td>{text(reviewLogbook.spec_details.nivel_llenado)}</td>
                            <td>{text(reviewLogbook.tightness_tests.initial_level_gas)}</td>
                            <td>{text(reviewLogbook.tightness_tests.initial_level_temp)}</td>
                            <td>{text(reviewLogbook.requirements_survey.director_psi_presion)}</td>
                            <td>{parseResults(reviewLogbook)}</td>
                            <td colSpan={2}>{text(reviewLogbook.requirements_survey.is_director_compliant)}</td>
                        </tr>
                        <tr>
                            <td colSpan={8}>{reviewLogbook.requirements_survey.director_observations}</td>
                        </tr>
                        <tr>
                            <td colSpan={8} className='subtitle'>Gráfico de Resultados</td>
                        </tr>
                        <tr>
                            <td colSpan={8}>
                                { reviewLogbook.results_graphic_uri ? (
                                    <img alt="Grafico de Resultados" src={reviewLogbook.results_graphic_uri} width="100%" />
                                ) : <em>Sin gráfico de resultados adjunto</em>}
                            </td>
                        </tr>
                    </tbody>
                </table>
            ))}
            <table className='table dewar_template_table'>
                <tbody>
                    <tr>
                        <td className='title text-left'>6.2  RESULTADOS PRUEBA HERMETICIDAD TUBERÍAS</td>
                    </tr>
                    {/* <tr>
                        <td className='bg-light'>{text(serviceReport.results_details.prueba_hermeticidad)}</td>
                    </tr> */}
                </tbody>
            </table>
            { serviceReview.review_logbooks.filter(r => r.spec_type === 'pipeline').map(reviewLogbook => (
                <table key={reviewLogbook.id} className='table dewar_template_table'>
                    <tbody>
                        <tr>
                            <td colSpan={2} className='title text-right'>Nº Tubería</td>
                            <td colSpan={1} className='title text-left'>{reviewLogbook.spec_number}</td>
                            <td colSpan={2} className='title text-right'>Fecha Inspección</td>
                            <td colSpan={1} className='title text-left'>{reviewLogbook.register_at}</td>
                        </tr>
                        <tr>
                            <td className='subtitle'>Desde el Tanque</td>
                            <td className='subtitle'>Hacia el Equipo:</td>
                            <td className='subtitle'>Producto</td>
                            <td className='subtitle'>Presión de la Prueba / PSI</td>
                            <td className='subtitle'>Resultados de la Prueba</td>
                            <td className='subtitle'>Revisión y Decisión del Director Técnico</td>
                        </tr>
                        <tr>
                            <td>{text(reviewLogbook.spec_details.desde_tanque)}</td>
                            <td>{text(reviewLogbook.spec_details.hacia)}</td>
                            <td>{reviewLogbook.product_name !== 'Otro' ? reviewLogbook.product_name : reviewLogbook.product_other_name}</td>
                            <td>{text(reviewLogbook.requirements_survey.director_psi_presion)}</td>
                            <td>{parseResults(reviewLogbook)}</td>
                            <td>{text(reviewLogbook.requirements_survey.is_director_compliant)}</td>
                        </tr>
                        <tr>
                            <td colSpan={6}>{reviewLogbook.requirements_survey.director_observations}</td>
                        </tr>
                        <tr>
                            <td colSpan={6} className='subtitle'>Gráfico de Resultados</td>
                        </tr>
                        <tr>
                            <td colSpan={6}>
                                { reviewLogbook.results_graphic_uri ? (
                                    <img alt="Grafico de Resultados" src={reviewLogbook.results_graphic_uri} width="100%" />
                                ) : <em>Sin gráfico de resultados adjunto</em>}
                            </td>
                        </tr>
                    </tbody>
                </table>
            ))}
            <table className='table dewar_template_table'>
                <tbody>
                    <tr>
                        <td className='title text-left'>6.3. RESULTADOS CONFORME</td>
                    </tr>
                    <tr>
                        <td className='bg-light'>{text(serviceReport.results_details.resultados_conforme)}</td>
                    </tr>
                    <tr>
                        <td className='title text-left'>6.4. RESULTADOS NO CONFORME</td>
                    </tr>
                    <tr>
                        <td className='bg-light'>{text(serviceReport.results_details.resultados_no_conforme)}</td>
                    </tr>
                    <tr>
                        <td className='title text-left'>6.5. OBSERVACIONES ADICIONALES</td>
                    </tr>
                    { serviceReport.results_details.obs_extra_tanques_subterraneos && (
                        <tr>
                            <td className='bg-light'>{text(serviceReport.results_details.obs_extra_tanques_subterraneos)}</td>
                        </tr>
                    )}
                    { serviceReport.results_details.obs_extra_doble_contencion && (
                        <tr>
                            <td className='bg-light'>{text(serviceReport.results_details.obs_extra_doble_contencion)}</td>
                        </tr>
                    )}
                    <tr>
                        <td className='bg-light'>{text(serviceReport.results_details.observaciones_adicionales)}</td>
                    </tr>
                    <tr>
                        <td className='title text-left'>7. EXPEDIENTE RELACIONADO</td>
                    </tr>
                    <tr>
                        <td className='bg-light'>Hacen parte del expediente del proceso: Acta de Inspección y Bitácoras.</td>
                    </tr>
                </tbody>
            </table>
            <table className='table dewar_template_table'>
                <tbody>
                    <tr>
                        <td colSpan={2} className='title text-left'>8. PERSONAL AUTORIZADO PARA LA ELABORACIÓN DEL INFORME:</td>
                    </tr>
                    <tr>
                        <td width="250px" className='title text-left'>Nombre del Director Técnico:</td>
                        <td>{serviceReport.director_user.name}</td>
                    </tr>
                    <tr>
                        <td className='title text-left'>Declaración Imparcialidad, Independencia y Confidencialidad</td>
                        <td>En calidad miembro de Director Técnico, declaro que en un lapso no inferior a los (2) años previos a la realización de la presente revisión o decisión, NO he realizado actividades en los ítems inspeccionados de:<br />
                        -	Fabricación, Instalación y/o revestimiento  de tanques<br />
                        -	Instalación de tuberías<br />
                        También declaro que en la presente revisión y decisión manejaré la información observada en la instalación de manera confidencial con lo cual me comprometo a seguir los lineamientos establecidos por el Organismo de Inspección DEWAR S.A.S., para el desarrollo  tales como procedimientos, guías y formularios con el fin de no poner en riesgo el nombre de DEWAR S.A.S.<br />
                        Así mismo, declaró no presentar conflicto de interés con las inspecciones relacionadas para las cuales he sido convocado como Director Técnico, y me comprometo a su realización bajo el cumplimiento de los procedimientos del DEWAR como Organismo de Inspección, y el Reglamento de inspección.<br /> Por último, declaro revisar y decidir fuera de presiones de alguna índole, y las decisiones tomadas corresponde netamente a mi competencia profesional guiadas por el cumplimiento del Reglamento Técnico vigente objeto del proceso de inspección.</td>
                    </tr>
                    <tr>
                        <td className='bg-light bold text-left'>FIRMA DIRECTOR TÉCNICO <br />DEWAR SAS OIN</td>
                        <td>
                            <img src={`/images/firma_${serviceReport.director_user_id}.jpg`} alt="firma" className='img-firma' />
                        </td>
                    </tr>
                </tbody>
            </table>
        </Container>
    )
}

const Container = styled.div`
	font-family: 'Arial';
	font-size: 13px;
	line-height: 15px;
	width: 21.59cm;
	min-height: 27.94cm;
	margin 0 auto;
	background-color: white;
	padding: 0.5cm;
	overflow: hidden;
	.img-firma {
		width: 90px;
		height: auto;
	}
`