import React from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import { is_positive } from '../../../../helpers'

export default function ReviewLogbookTightnessTestsForm({ register, errors, watch, setValue, specType }) {

    return (
        <React.Fragment>
            { specType === 'tank' ? (
                <React.Fragment>
                    <Row>
                        <Form.Group as={Col}>
                            <Form.Label>Nivel combustible (cm) Inicial</Form.Label>
                            <Form.Control 
                                {...register('tightness_tests.initial_level_gas', {})}
                                placeholder='Escriba aquí...'
                            />
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>Nivel combustible (cm) Final</Form.Label>
                            <Form.Control 
                                {...register('tightness_tests.final_level_gas', {})}
                                placeholder='Escriba aquí...'
                            />
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group as={Col}>
                            <Form.Label>Nivel agua (cm) Inicial</Form.Label>
                            <Form.Control 
                                {...register('tightness_tests.initial_level_water', {})}
                                placeholder='Escriba aquí...'
                            />
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>Nivel agua (cm) Final</Form.Label>
                            <Form.Control 
                                {...register('tightness_tests.final_level_water', {})}
                                placeholder='Escriba aquí...'
                            />
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group as={Col}>
                            <Form.Label>Temperatura ºC Inicial</Form.Label>
                            <Form.Control 
                                {...register('tightness_tests.initial_level_temp', {})}
                                placeholder='Escriba aquí...'
                            />
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>Temperatura ºC Final</Form.Label>
                            <Form.Control 
                                {...register('tightness_tests.final_level_temp', {})}
                                placeholder='Escriba aquí...'
                            />
                        </Form.Group>
                    </Row>
                </React.Fragment>
            ) : (
                <Form.Group>
                    <Form.Label>¿Si se desactivo la bomba sumergible?</Form.Label>
                    <Form.Control
                        as="select"
                        {...register('tightness_tests.bomb_inanctive_by_client', {})}
                    >
                        <option value="">:: Selecciona una opción ::</option>
                        <option>Sí</option>
                        <option>No</option>
                    </Form.Control>
                    { is_positive(watch('tightness_tests.bomb_inanctive_by_client')) ? (
                        <Form.Control
                            as="select"
                            {...register('tightness_tests.bomb_inanctive_by_client_obs', {})}
                        >
                            <option value="">:: Selecciona una opción ::</option>
                            <option>Cliente</option>
                            <option>Dewar</option>
                        </Form.Control>
                    ) : (
                        <Form.Control 
                            as="textarea"
                            {...register('tightness_tests.bomb_inanctive_by_client_obs', {})}
                            placeholder='Escriba observaciones si tiene'
                            style={{ height: '70px' }}
                        />
                    )}
                </Form.Group>
            )}
            <Row>
                <Col xs={2}>
                    <p className='bold mt-20 mb-5'>Prueba #1</p>
                </Col>
                <Form.Group as={Col}>
                    <Form.Label>Hora</Form.Label>
                    <Form.Control 
                        {...register('tightness_tests.test1_time', {})}
                        type="time"
                    />
                    <Form.Text className='text-warning'>Al modificar esta hora se modificara todas las demás.</Form.Text>
                </Form.Group>
                <Form.Group as={Col}>
                    <Form.Label>Presión (PSI)</Form.Label>
                    <Form.Control 
                        {...register('tightness_tests.test1_presion', {})}
                    />
                </Form.Group>
            </Row>
            <Row>
                <Col xs={2}>
                    <p className='bold mt-20 mb-5'>Prueba #2</p>
                </Col>
                <Form.Group as={Col}>
                    <Form.Label>Hora</Form.Label>
                    <Form.Control 
                        {...register('tightness_tests.test2_time', {})}
                        type="time"
                    />
                </Form.Group>
                <Form.Group as={Col}>
                    <Form.Label>Presión (PSI)</Form.Label>
                    <Form.Control 
                        {...register('tightness_tests.test2_presion', {})}
                    />
                </Form.Group>
            </Row>
            <Row>
                <Col xs={2}>
                    <p className='bold mt-20 mb-5'>Prueba #3</p>
                </Col>
                <Form.Group as={Col}>
                    <Form.Label>Hora</Form.Label>
                    <Form.Control 
                        {...register('tightness_tests.test3_time', {})}
                        type="time"
                    />
                </Form.Group>
                <Form.Group as={Col}>
                    <Form.Label>Presión (PSI)</Form.Label>
                    <Form.Control 
                        {...register('tightness_tests.test3_presion', {})}
                    />
                </Form.Group>
            </Row>
            <Row>
                <Col xs={2}>
                    <p className='bold mt-20 mb-5'>Prueba #4</p>
                </Col>
                <Form.Group as={Col}>
                    <Form.Label>Hora</Form.Label>
                    <Form.Control 
                        {...register('tightness_tests.test4_time', {})}
                        type="time"
                    />
                </Form.Group>
                <Form.Group as={Col}>
                    <Form.Label>Presión (PSI)</Form.Label>
                    <Form.Control 
                        {...register('tightness_tests.test4_presion', {})}
                    />
                </Form.Group>
            </Row>
            <Row>
                <Col xs={2}>
                    <p className='bold mt-20 mb-5'>Prueba #5</p>
                </Col>
                <Form.Group as={Col}>
                    <Form.Label>Hora</Form.Label>
                    <Form.Control 
                        {...register('tightness_tests.test5_time', {})}
                        type="time"
                    />
                </Form.Group>
                <Form.Group as={Col}>
                    <Form.Label>Presión (PSI)</Form.Label>
                    <Form.Control 
                        {...register('tightness_tests.test5_presion', {})}
                    />
                </Form.Group>
            </Row>
            <Row>
                <Col xs={2}>
                    <p className='bold mt-20 mb-5'>Prueba #6</p>
                </Col>
                <Form.Group as={Col}>
                    <Form.Label>Hora</Form.Label>
                    <Form.Control 
                        {...register('tightness_tests.test6_time', {})}
                        type="time"
                    />
                </Form.Group>
                <Form.Group as={Col}>
                    <Form.Label>Presión (PSI)</Form.Label>
                    <Form.Control 
                        {...register('tightness_tests.test6_presion', {})}
                    />
                </Form.Group>
            </Row>
            <Row>
                <Col xs={2}>
                    <p className='bold mt-20 mb-5'>Prueba #7</p>
                </Col>
                <Form.Group as={Col}>
                    <Form.Label>Hora</Form.Label>
                    <Form.Control 
                        {...register('tightness_tests.test7_time', {})}
                        type="time"
                    />
                </Form.Group>
                <Form.Group as={Col}>
                    <Form.Label>Presión (PSI)</Form.Label>
                    <Form.Control 
                        {...register('tightness_tests.test7_presion', {})}
                    />
                </Form.Group>
            </Row>
        </React.Fragment>
    )
}