import React from 'react'
import styled from 'styled-components'

import DocumentHeader from '../../../../components/DocumentHeader'
import logo from '../../../../assets/images/logo_dewar_onac.png'
import { text } from '../../../../helpers'

export default function REVIEW_LOGBOOK_TEMPLATE_PRU02({ reviewLogbook }) {
    
    const serviceInspector = reviewLogbook.service_review.service_plan.service_inspector
    const service = serviceInspector.service
    const inquiry = service.proposal.inquiry
    const branch = inquiry.branch_spec.branch

    return (
        <Container>
            <DocumentHeader
				title="BITACORA DE INSPECCIÓN TUBERÍAS"
				version={reviewLogbook.format_version.sku}
				expireAt={reviewLogbook.format_version.expire_at}
				proposalSku={service.proposal.sku}
				logoSrc={logo}
			/>
            <table className='table all_borders'>
            <tbody>
                    <tr>
                        <td colSpan={4} className='small text-center'>El presente formato es entregado para desarrollo exclusivo de la labor o servicio que han sido contratados. Su uso no autorizado constituye infracción de la confidencialidad y al secreto empresarial. No podrá ser copiado o rediseñado sin autorización expresa del titular DEWAR SAS</td>
                    </tr>
                    <tr>
                        <td className='title' width="200px">Fecha:</td>
                        <td>{reviewLogbook.register_at}</td>
                        <td className='title' width="200px">Inspector asignado:</td>
                        <td>{serviceInspector.user_name}</td>
                    </tr>
                    <tr>
                        <td className='title'>Nombre de Instalación:</td>
                        <td colSpan={3}>{branch.name}</td>
                    </tr>
                    <tr>
                        <td className='title'>Criterio de la Inspección:</td>
                        <td colSpan={3}>{service.criteria}</td>
                    </tr>
                </tbody>
            </table>
            <table className='table all_borders'>
                <tbody>
                    <tr>
                        <td colSpan={6} className='title'>MODULO 1. IDENTIFICACIÓN DE LA TUBERÍA</td>
                    </tr>
                    <tr>
                        <td className='title' width="110px">Nº Tubería</td>
                        <td width="120px">{reviewLogbook.spec_number}</td>
                        <td className='title' width="110px">Desde el tanque</td>
                        <td>{text(reviewLogbook.spec_details.desde_tanque)}</td>
                        <td className='title' width="110px">Hacia equipo</td>
                        <td>{text(reviewLogbook.spec_details.hacia)}</td>
                    </tr>
                    <tr>
                        <td className='title'>Producto</td>
                        <td width="120px">{reviewLogbook.product_name !== 'Otro' ? reviewLogbook.product_name : reviewLogbook.product_other_name}</td>
                        <td className='title'>Isla de llenado</td>
                        <td>{text(reviewLogbook.spec_details.isla_llenado)}</td>
                        <td className='title'>Equipo</td>
                        <td>{reviewLogbook.spec_category_slug}</td>
                    </tr>
                </tbody>
            </table>
            <table className='table all_borders mt-20'>
                <tbody>
                    <tr>
                        <td colSpan={3} className='subtitle'>MODULO 2. PREGUNTAS INSTALACIÓN HIDRÁULICAS</td>
                    </tr>
                    <tr>
                        <td className='subtitle'>1. Aspectos a  verificar</td>
                        <td className='subtitle' width="100px">Respuesta</td>
                        <td className='subtitle' width="180px">Observaciones (Opcional)</td>
                    </tr>
                    { reviewLogbook.spec_category_slug === 'dispensadores' && (
                        <tr>
                            <td>Verificación de cierre de válvula de bomba sumergible</td>
                            <td>{text(reviewLogbook.survey_answers.verifica_cierre)}</td>
                            <td>{text(reviewLogbook.survey_answers.verifica_cierre_obs)}</td>
                        </tr>
                    )}
                    <tr>
                        <td>Verificación de activación de válvula de impacto</td>
                        <td>{text(reviewLogbook.survey_answers.verifica_activacion)}</td>
                        <td>{text(reviewLogbook.survey_answers.verifica_activacion_obs)}</td>
                    </tr>
                    <tr>
                        <td>Verificación de tubería desconectada y taponada</td>
                        <td>{text(reviewLogbook.survey_answers.verifica_desconectada)}</td>
                        <td>{text(reviewLogbook.survey_answers.verifica_desconectada_obs)}</td>
                    </tr>
                    <tr>
                        <td>El nitrógeno se inyectó desde:</td>
                        <td colSpan={2}>{text(reviewLogbook.survey_answers.nitrogeno_desde)}</td>
                    </tr>
                    <tr>
                        <td colSpan={3} className='subtitle'>MODULO 3. EJECUCIÓN PRUEBAS DE LÍNEA - TUBERIA</td>
                    </tr>
                    <tr>
                        <td>¿Si se desactivo la bomba sumergible?</td>
                        <td>{text(reviewLogbook.tightness_tests.bomb_inanctive_by_client)}</td>
                        <td>{text(reviewLogbook.tightness_tests.bomb_inanctive_by_client_obs)}</td>
                    </tr>
                </tbody>
            </table>
            <table className='table all_borders'>
                <tbody>
                    <tr>
                        <td colSpan={8} className='subtitle'>REGISTROS PRUEBA DE VERIFICACION DE LA HERMETICIDAD TUBERÍA</td>
                    </tr>
                    <tr className='text-center'>
                        <td width="120px" className='bold'>Hora</td>
                        <td width="100px">{text(reviewLogbook.tightness_tests.test1_time)}</td>
                        <td width="100px">{text(reviewLogbook.tightness_tests.test2_time)}</td>
                        <td width="100px">{text(reviewLogbook.tightness_tests.test3_time)}</td>
                        <td width="100px">{text(reviewLogbook.tightness_tests.test4_time)}</td>
                        <td width="100px">{text(reviewLogbook.tightness_tests.test5_time)}</td>
                        <td width="100px">{text(reviewLogbook.tightness_tests.test6_time)}</td>
                        <td width="100px">{text(reviewLogbook.tightness_tests.test7_time)}</td>
                    </tr>
                    <tr className="text-center">
                        <td width="120px" className='bold'>Presión (PSI)</td>
                        <td>{text(reviewLogbook.tightness_tests.test1_presion)}</td>
                        <td>{text(reviewLogbook.tightness_tests.test2_presion)}</td>
                        <td>{text(reviewLogbook.tightness_tests.test3_presion)}</td>
                        <td>{text(reviewLogbook.tightness_tests.test4_presion)}</td>
                        <td>{text(reviewLogbook.tightness_tests.test5_presion)}</td>
                        <td>{text(reviewLogbook.tightness_tests.test6_presion)}</td>
                        <td>{text(reviewLogbook.tightness_tests.test7_presion)}</td>
                    </tr>
                </tbody>
            </table>
            <table className='table all_borders mt-20'>
                <tbody>
                    <tr>
                        <td colSpan={4} className='title'>MODULO 4. LISTA DE VERIFICACIÓN TUBERÍAS</td>
                    </tr>
                    <tr>
                        <td colSpan={2} className='title'>Requisitos</td>
                        <td className='title'>Cumplimiento</td>
                        <td className='title'>Observaciones (Opcional)</td>
                    </tr>
                    <tr>
                        <td width="30px" className='bold'>4.1</td>
                        <td>La tubería inspeccionada alcanza la presión mínima recomendada por el fabricante ó la establecida por el procedimiento</td>
                        <td width="100px">{text(reviewLogbook.requirements_survey.item_0410)}</td>
                        <td width="180px">{text(reviewLogbook.requirements_survey.item_0410_obs)}</td>
                    </tr>
                    <tr>
                        <td width="30px" className='bold'>4.2</td>
                        <td>La tubería inspeccionada mantiene durante el procedimiento de la prueba la presión mínima recomendada por el fabricante ó la establecida por el procedimiento</td>
                        <td width="100px">{text(reviewLogbook.requirements_survey.item_0420)}</td>
                        <td width="180px">{text(reviewLogbook.requirements_survey.item_0420_obs)}</td>
                    </tr>
                </tbody>
            </table>
        </Container>
    )
}

const Container = styled.div`
    font-family: 'Arial';
	font-size: 13px;
	line-height: 15px;
	width: 21.59cm;
	min-height: 27.94cm;
	margin 0 auto;
	background-color: white;
	padding: 0.5cm;
	overflow: hidden;
	& table tr td {
		border: 1px solid black;
		padding: 4px 3px;
        vertical-align: middle;
	}
    & table.table {
        margin-bottom: 3px;
    }
    background-color: #fff;
    span.variable {
        color: #e78035;
        font-weight: 600;
    }
    h5 {
        font-size: 16px;
        font-weight: bold;
    }
    & table tr td.title {
        background-color: #f6c6ac;
        font-weight: bold;
        text-align: center;
    }
    & table tr td.subtitle {
        background-color: #fae2d5;
        font-weight: bold;
        text-align: center;
    }
`