import React from 'react'

import { text } from '../../../../helpers'

export default function ReviewLogbookTightnessTestsTable({ reviewLogbook }) {
    return (
        <React.Fragment>
            <table className='table all_borders compact_table'>
                <tbody>
                    { reviewLogbook.spec_type === 'tank' ? (
                        <React.Fragment>
                            <tr className='text-center'>
                                <td colSpan={2} className='bold bg-light'>Nivel combustible (cm) Inicial</td>
                                <td colSpan={2}>{text(reviewLogbook.tightness_tests.initial_level_gas)}</td>
                                <td colSpan={2} className='bold bg-light'>Nivel combustible (cm) Final</td>
                                <td colSpan={2}>{text(reviewLogbook.tightness_tests.final_level_gas)}</td>
                            </tr>
                            <tr className='text-center'>
                                <td colSpan={2} className='bold bg-light'>Nivel agua (cm) Inicial</td>
                                <td colSpan={2}>{text(reviewLogbook.tightness_tests.initial_level_water)}</td>
                                <td colSpan={2} className='bold bg-light'>Nivel agua (cm) Final</td>
                                <td colSpan={2}>{text(reviewLogbook.tightness_tests.final_level_water)}</td>
                            </tr>
                            <tr className='text-center'>
                                <td colSpan={2} className='bold bg-light'>Temperatura ºC Inicial</td>
                                <td colSpan={2}>{text(reviewLogbook.tightness_tests.initial_level_temp)}</td>
                                <td colSpan={2} className='bold bg-light'>Temperatura ºC Final</td>
                                <td colSpan={2}>{text(reviewLogbook.tightness_tests.final_level_temp)}</td>
                            </tr>
                        </React.Fragment>
                    ) : (
                        <tr className='text-center'>
                            <td colSpan={3} className='text-right bold bg-light'>activo la bomba sumergible?</td>
                            <td colSpan={2}>{text(reviewLogbook.tightness_tests.bomb_inanctive_by_client)}</td>
                            <td colSpan={3}>{text(reviewLogbook.tightness_tests.bomb_inanctive_by_client_obs)}</td>
                        </tr>
                    )}
                    <tr>
                        <td width="100px" className='bg-light'><strong>Hora</strong></td>
                        <td width="100px">{text(reviewLogbook.tightness_tests.test1_time)}</td>
                        <td width="100px">{text(reviewLogbook.tightness_tests.test2_time)}</td>
                        <td width="100px">{text(reviewLogbook.tightness_tests.test3_time)}</td>
                        <td width="100px">{text(reviewLogbook.tightness_tests.test4_time)}</td>
                        <td width="100px">{text(reviewLogbook.tightness_tests.test5_time)}</td>
                        <td width="100px">{text(reviewLogbook.tightness_tests.test6_time)}</td>
                        <td width="100px">{text(reviewLogbook.tightness_tests.test7_time)}</td>
                    </tr>
                    <tr>
                        <td className='bg-light'><strong>Presión (PSI)</strong></td>
                        <td>{text(reviewLogbook.tightness_tests.test1_presion)}</td>
                        <td>{text(reviewLogbook.tightness_tests.test2_presion)}</td>
                        <td>{text(reviewLogbook.tightness_tests.test3_presion)}</td>
                        <td>{text(reviewLogbook.tightness_tests.test4_presion)}</td>
                        <td>{text(reviewLogbook.tightness_tests.test5_presion)}</td>
                        <td>{text(reviewLogbook.tightness_tests.test6_presion)}</td>
                        <td>{text(reviewLogbook.tightness_tests.test7_presion)}</td>
                    </tr>
                </tbody>
            </table>
        </React.Fragment>
    )
}