import React from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import { is_positive } from '../../../../helpers';

export default function ReviewLogbookSpecDetailsForm({ register, errors, watch, specType }) {

    switch (specType) {
        case 'tank':
            return (
                <React.Fragment>
                    <Form.Group>
                        <Form.Label>Identificación del tanque</Form.Label>
                        <Form.Control 
                            {...register('spec_details.identificacion_tanque', {})}
                            placeholder='Escriba aquí'
                        />
                    </Form.Group>
                    <Row>
                        <Form.Group as={Col}>
                            <Form.Label>Capacidad (gal)</Form.Label>
                            <Form.Control 
                                {...register('spec_details.capacidad', {})}
                                placeholder='Escriba aquí'
                            />
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>Material</Form.Label>
                            <Form.Control 
                                as="select"
                                {...register('spec_details.material', {})}
                            >
                                <option value="">:: Selecione una opción ::</option>
                                <option>Fibra</option>
                                <option>Acero</option>
                            </Form.Control>
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group as={Col}>
                            <Form.Label>Nivel de llenado (gal)</Form.Label>
                            <Form.Control 
                                {...register('spec_details.nivel_llenado', {})}
                                placeholder='Escriba aquí'
                            />
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>Diámetro de tanque (m)</Form.Label>
                            <Form.Control 
                                {...register('spec_details.diametro_tanque', {})}
                                placeholder='Escriba aquí'
                            />
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group as={Col}>
                            <Form.Label>Compartido</Form.Label>
                            <Form.Control 
                                as="select"
                                {...register('spec_details.bicompartido', {})}
                            >
                                <option value="">:: Selecione una opción ::</option>
                                <option>Sí</option>
                                <option>No</option>
                            </Form.Control>
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>Doble contención</Form.Label>
                            <Form.Control 
                                as="select"
                                {...register('spec_details.doble_contencion', {})}
                            >
                                <option value="">:: Selecione una opción ::</option>
                                <option>Sí</option>
                                <option>No</option>
                            </Form.Control>
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group as={Col}>
                            <Form.Label>Bypass</Form.Label>
                            <Form.Control 
                                as="select"
                                {...register('spec_details.bypass', {})}
                            >
                                <option value="">:: Selecione una opción ::</option>
                                <option>Sí</option>
                                <option>No</option>
                            </Form.Control>
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>Tiene sifón</Form.Label>
                            <Form.Control 
                                as="select"
                                {...register('spec_details.tiene_sifon', {})}
                            >
                                <option value="">:: Selecione una opción ::</option>
                                <option>Sí</option>
                                <option>No</option>
                            </Form.Control>
                        </Form.Group>
                    </Row>
                    { is_positive(watch('spec_details.doble_contencion')) && (
                        <React.Fragment>
                            <Form.Group>
                                <Form.Label>Hay contaminación por olor ó existe traza de combustible en el Intersticio</Form.Label>
                                <Form.Control 
                                    as="select"
                                    {...register('spec_details.olor_hc', {})}
                                >
                                    <option value="">:: Selecione una opción ::</option>
                                    <option>Sí</option>
                                    <option>No</option>
                                </Form.Control>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Espacio Anular</Form.Label>
                                <Form.Control 
                                    as="select"
                                    {...register('spec_details.espacio_anular', {})}
                                >
                                    <option value="">:: Selecione una opción ::</option>
                                    <option>Sí</option>
                                    <option>No</option>
                                </Form.Control>
                            </Form.Group>
                            <Row>
                                <Form.Group as={Col}>
                                    <Form.Label>Nivel de Salmuera Inicial (cm)</Form.Label>
                                    <Form.Control 
                                        {...register('spec_details.salmuera_inicial', {})}
                                        placeholder='Escriba aquí'
                                    />
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Form.Label>Nivel de Salmuera Final (cm)</Form.Label>
                                    <Form.Control 
                                        {...register('spec_details.salmuera_final', {})}
                                        placeholder='Escriba aquí'
                                    />
                                </Form.Group>
                            </Row>
                        </React.Fragment>
                    )}
                    <Form.Group>
                        <Form.Label>No. De bocas</Form.Label>
                        <Form.Control 
                            {...register('spec_details.numero_bocas', {})}
                            placeholder='Escriba aquí'
                        />
                    </Form.Group>
                    <Row>
                        <Form.Group as={Col}>
                            <Form.Label>Diámetro de bocas (pulg)</Form.Label>
                            <Form.Control 
                                {...register('spec_details.diametro_bocas', {})}
                                placeholder='Escriba aquí'
                            />
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>Diámetro de manhole (pulg)</Form.Label>
                            <Form.Control 
                                {...register('spec_details.diametro_manhole', {})}
                                placeholder='Escriba aquí'
                            />
                        </Form.Group>
                    </Row>
                </React.Fragment>
            )

        case 'pipeline':
            return (
                <React.Fragment>
                    <Row>
                        <Form.Group as={Col}>
                            <Form.Label>Desde el tanque</Form.Label>
                            <Form.Control 
                                {...register('spec_details.desde_tanque', {})}
                                placeholder='Escriba aquí'
                            />
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>Hacia Equipos</Form.Label>
                            <Form.Control 
                                {...register('spec_details.hacia', {})}
                                placeholder='Escriba aquí'
                            />
                        </Form.Group>
                    </Row>
                    <Form.Group>
                        <Form.Label>Isla de llenado</Form.Label>
                        <Form.Control 
                            {...register('spec_details.isla_llenado', {})}
                            placeholder='Escriba aquí'
                        />
                    </Form.Group>
                </React.Fragment>
            )
    
        default:
            return;
    }

    
}