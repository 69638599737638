import dewarApi, { getDomain } from '../../services/dewarApi'

export const getReviewEquipments = params => (
	new Promise((resolve, reject) => {
		dewarApi()
			.get('/review_equipments', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const getReviewEquipment = (id, params) => (
	new Promise((resolve, reject) => {
		dewarApi()
			.get(`/review_equipments/${id}`, { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeReviewEquipment = data => (
	new Promise((resolve, reject) => {
		dewarApi()
			.post('/review_equipments', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateReviewEquipment = (id, data) => (
	new Promise((resolve, reject) => {
		dewarApi()
			.put(`/review_equipments/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deleteReviewEquipment = id => (
	new Promise((resolve, reject) => {
		dewarApi()
			.delete(`/review_equipments/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const searchReviewEquipments = async (name, token) => (
	fetch( getDomain()+`/review_equipments?filter[name]=${name}`, {
		headers: {'Authorization': `Bearer ${token}`}
	})
		.then((response) => response.json())
		.then((res) =>
			res.data.map(service_review => ({
				label: service_review.name,
				value: service_review.id,
			})),
		)
)