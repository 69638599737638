import React, { useState } from 'react'
import { Alert, Form, Spinner } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { message } from 'antd'

import ServiceReportDetialsForm from '../forms/ServiceReportDetialsForm'

import { updateServiceReport } from '../../services'
import { handleError, is_positive } from '../../../../helpers'

export default function EditServiceReportDetailsModal({ visible, onClose, reload, serviceReport }) {
    const [loading, setLoading] = useState(false)
    const editDetails = serviceReport.edit_details
    const branch = serviceReport.service_review.service_plan.service_inspector.service.proposal.inquiry.branch_spec.branch
    const { handleSubmit, register, formState: { errors } } = useForm({
        defaultValues: {
            'edit_details.edit_count': (editDetails && editDetails.edit_count) ? editDetails.edit_count : 1,
            'edit_details.edit_label': (editDetails && editDetails.edit_label) ? editDetails.edit_label : 'Emisión del Informe',
            'edit_details.edit_date': (editDetails && editDetails.edit_date) ? editDetails.edit_date : '',
            'edit_details.company_name': (editDetails && editDetails.company_name) ? editDetails.company_name : branch.company.name,
            'edit_details.company_document': (editDetails && editDetails.company_document) ? editDetails.company_document : branch.company.complete_document,
            'edit_details.legal_owner_name': (editDetails && editDetails.legal_owner_name) ? editDetails.legal_owner_name : branch.company.legal_owner_name,
            'edit_details.branch_name': (editDetails && editDetails.branch_name) ? editDetails.branch_name : branch.name,
            'edit_details.branch_email': (editDetails && editDetails.branch_email) ? editDetails.branch_email : branch.email,
            'edit_details.branch_address_line': (editDetails && editDetails.branch_address_line) ? editDetails.branch_address_line : branch.address_line,
            'edit_details.branch_address_state': (editDetails && editDetails.branch_address_state) ? editDetails.branch_address_state : branch.address_state,
            'edit_details.branch_address_city': (editDetails && editDetails.branch_address_city) ? editDetails.branch_address_city : branch.address_city,
        }
    })

    const onSubmit = values => {
        setLoading(true)

        values.edit_details.edit_count = is_positive(values.count_as_modification) ? parseInt(values.edit_details.edit_count) + 1 : values.edit_details.edit_count
        if(values.edit_details.company_name === branch.company.name) delete values.edit_details.company_name
        if(values.edit_details.company_document === branch.company.complete_document) delete values.edit_details.company_document
        if(values.edit_details.legal_owner_name === branch.company.legal_owner_name) delete values.edit_details.legal_owner_name
        if(values.edit_details.branch_name === branch.name) delete values.edit_details.branch_name
        if(values.edit_details.branch_email === branch.email) delete values.edit_details.branch_email
        if(values.edit_details.branch_address_line === branch.address_line) delete values.edit_details.branch_address_line
        if(values.edit_details.branch_address_state === branch.address_state) delete values.edit_details.branch_address_state
        if(values.edit_details.branch_address_city === branch.address_city) delete values.edit_details.branch_address_city

        updateServiceReport(serviceReport.id, values)
            .then(res => {
                message.success(res.data.message)
                reload()
                onClose()
            })
            .catch(error => handleError(error, setLoading))
    }

    return (
        <Modal isOpen={visible}>
            <ModalHeader toggle={onClose}>Modificar Detalles del Informe</ModalHeader>
            <ModalBody>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Alert variant='warning' className='p-5'>
                        Todos los cambios que realice en este formulario solo se verán reflejados en este Informe de Inspección
                    </Alert>
                    <ServiceReportDetialsForm 
                        register={register}
                        errors={errors}
                    />
                    <Button color="primary" type="submit" disabled={loading}>
                        { loading && <Spinner animation='border' size="sm" className='mr-5' /> }
                        Actualizar Informe
                    </Button>
                </Form>
            </ModalBody>
        </Modal>
    )
}