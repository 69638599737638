import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { message } from 'antd'
import { Alert, Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { Form, Spinner } from 'react-bootstrap'
import moment from 'moment'

import ReviewEquipmentForm from './ReviewEquipmentForm'

import { updateReviewEquipment } from '../services'
import { handleError } from '../../../helpers'

export default function EditReviewEquipmentModal({ visible, onClose, reviewEquipment, reload }) {
	const [loading, setLoading] = useState(false)
	const { register, handleSubmit, formState: { errors } } = useForm({
		defaultValues: {
			item: reviewEquipment.item,
			name: reviewEquipment.name,
			brand: reviewEquipment.brand,
			serial: reviewEquipment.serial,
			code: reviewEquipment.code,
			verified_at: moment(reviewEquipment.verified_at).format('YYYY-MM-DD')
		}
	})

	const onSubmit = values => {
		setLoading(true)
		updateReviewEquipment(reviewEquipment.id, values)
			.then(res => {
				message.success(res.data.message)
				onClose()
				reload()
			})
			.catch(error => handleError(error, setLoading))
	}

	return (
		<React.Fragment>
			<Modal isOpen={visible}>
				<ModalHeader toggle={onClose}>Actualizar Equipo de Medición</ModalHeader>
				<ModalBody>
					<Alert color='info'>Los cambios que realice solo aplicarán para este servicio</Alert>
					<Form onSubmit={handleSubmit(onSubmit)}>
						<ReviewEquipmentForm register={register} errors={errors} />
						<Button color='primary' type='submit' disabled={loading}>
							{ loading && <Spinner animation='border' size="sm" className='mr-5' /> }
							Actualizar Equipo
						</Button>
					</Form>
				</ModalBody>
			</Modal>
		</React.Fragment>
	)
}