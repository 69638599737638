import React from 'react'
import { Form } from 'react-bootstrap'

import { is_positive } from '../../../../helpers'

export default function ReportResultsDetailsForm({ register, errors, serviceReport }) {

    const hasUndergroundTanks = () => {
        let tanks = serviceReport.service_review.review_logbooks.filter(reviewLogbook => reviewLogbook.spec_category_slug === 'subterraneo')
        return tanks.length > 0
    }
    
    const hasDoubleContainment = () => {
        let tanks = serviceReport.service_review.review_logbooks.filter(reviewLogbook => is_positive(reviewLogbook.spec_details.doble_contencion))
        return tanks.length > 0
    }

    return (
        <React.Fragment>
            {/* <Form.Group>
                <Form.Label>RESULTADOS PRUEBA HERMETICIDAD TUBERÍAS</Form.Label>
                <Form.Control 
                    {...register('results_details.prueba_hermeticidad', {})}
                    placeholder='Escribe aquí...'
                    as="textarea"
                    style={{ height: '70px' }}
                    defaultValue={serviceReport.results_details.prueba_hermeticidad}
                />
            </Form.Group> */}
            <Form.Group>
                <Form.Label>RESULTADOS CONFORME</Form.Label>
                <Form.Control 
                    {...register('results_details.resultados_conforme', {})}
                    placeholder='Escribe aquí...'
                    as="textarea"
                    style={{ height: '70px' }}
                    defaultValue={serviceReport.results_details.resultados_conforme ? serviceReport.results_details.resultados_conforme : 'Los tanques conformes al momento de la prueba:  Mantienen la presión establecida en el procedimiento en el Sistema de almacenamiento  durante el tiempo de la prueba de hermeticidad.  No se identifican fugas visibles. Los Tubos de Desfogue funcionan correctamente y no presetan perdida de presión. Las tuberías conformes al momento de la prueba: Las tuberías de conducción de combustible de los tanques a los equipos mantienen la presión. Las válvulas de impacto de los equipos funcionan correctamente.'}
                />
            </Form.Group>
            <Form.Group>
                <Form.Label>RESULTADOS NO CONFORME</Form.Label>
                <Form.Control 
                    {...register('results_details.resultados_no_conforme', {})}
                    placeholder='Escribe aquí...'
                    as="textarea"
                    style={{ height: '70px' }}
                    defaultValue={serviceReport.results_details.resultados_no_conforme ? serviceReport.results_details.resultados_no_conforme : 'Si alguno de los items presenta resultado no conforme, El Cliente  tendrá que realizar los correctivos necesarios y solicitar un proceso de inspección para evaluar la conformidad del tanque o tubería. El tanque o la tubería no conforme no se incluirá dentro del Certificado de inspección.'}
                />
            </Form.Group>
            { hasUndergroundTanks() && (
                <Form.Group>
                    <Form.Label>OBSERVACIONES ADICIONALES (Con Tanques Subterráneos)</Form.Label>
                    <Form.Control 
                        {...register('results_details.obs_extra_tanques_subterraneos', {})}
                        placeholder='Escribe aquí...'
                        as="textarea"
                        style={{ height: '70px' }}
                        defaultValue={serviceReport.results_details.obs_extra_tanques_subterraneos ? serviceReport.results_details.obs_extra_tanques_subterraneos : 'Antes y después de la prueba de tanques y tuberías se verificarón los niveles en los pozos de observación y/o monitores, revisando lo relacionado a la variación de niveles y si existe contaminación'}
                    />
                </Form.Group>
            )}
            { hasDoubleContainment() && (
                <Form.Group>
                    <Form.Label>OBSERVACIONES ADICIONALES (Con Doble Contención)</Form.Label>
                    <Form.Control 
                        {...register('results_details.obs_extra_doble_contencion', {})}
                        placeholder='Escribe aquí...'
                        as="textarea"
                        style={{ height: '70px' }}
                        defaultValue={serviceReport.results_details.obs_extra_doble_contencion ? serviceReport.results_details.obs_extra_doble_contencion : 'Antes y después de la prueba en los tanques de doble contención se verifica el espacio anular.'}
                    />
                </Form.Group>
            )}
            <Form.Group>
                <Form.Label>OBSERVACIONES ADICIONALES</Form.Label>
                <Form.Control 
                    {...register('results_details.observaciones_adicionales', {})}
                    placeholder='Escribe aquí...'
                    as="textarea"
                    style={{ height: '70px' }}
                    defaultValue={ serviceReport.results_details.observaciones_adicionales ? 
                            serviceReport.results_details.observaciones_adicionales : 
                            ( hasUndergroundTanks() ?  
                                'Antes y después de la prueba se verificarón los niveles en los pozos de observación y/o monitores para el caso de tanques enterrados así como el espacio anular en los tanques de doble contención, registrando las observaciones, nivel freático y presencia o no de contaminación tal como se muestra en la bitácora de servicio anexa.' :
                                ''
                            )                            
                        }
                />
            </Form.Group>
        </React.Fragment>
    )
}