import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Card, CardBody } from 'reactstrap'

import ListTopBar from '../../components/ListTopBar'
import ServiceReportsTable from './partials/ServiceReportsTable'
import InternetRequired from '../Offline/partials/InternetRequired'

import { getServiceReports } from './services'
import { handleError, parsePagination } from '../../helpers'

export default function ServiceReportsList() {
	const { isConnected } = useSelector(state => state.offline)
	const { user } = useSelector(state => state.auth)
	const [serviceReports, setServiceReports] = useState(null)
	const [filterType, setFilterType] = useState('serviceReview.servicePlan.serviceInspector.service.proposal.inquiry.branchSpec.branch.company.name')
	const [filterValue, setFilterValue] = useState('')
	const [filterBusinessScope, setFilterBusinessScope] = useState('')
	const [pagination, setPagination] = useState({page: 1, per_page: 15, total: 0})

	useEffect(() => {
		!serviceReports && isConnected && getServiceReports({
			...pagination,
			[`filter[${filterType}]`]: filterValue,
			include: 'serviceReview.servicePlan.serviceInspector.service.proposal.inquiry.branchSpec.branch.company',
			'filter[serviceReview.servicePlan.serviceInspector.user_id]': (user.role !== 'admin' && !user.role.includes('director')) ? user.id : '',
			'filter[formatVersion.business_scope]': filterBusinessScope,
			sort: '-created_at',
		})
			.then(res => {
				setServiceReports(res.data.data)
				setPagination(parsePagination(res))
			})
			.catch(error => handleError(error))
	}, [ serviceReports, filterType, filterValue, user, isConnected, pagination, filterBusinessScope ])

	if(!isConnected) return <InternetRequired />

	return (
		<React.Fragment>
			<ListTopBar 
				filterType={filterType}
				setFilterType={setFilterType}
				filterValue={filterValue}
				setFilterValue={setFilterValue}
				title="Informes de Inspección"
				subtitle="Plataforma DEWAR"
				reload={() => setServiceReports(null)}
				filterScope={filterBusinessScope}
				setFilterScope={setFilterBusinessScope}
				scopeLabel="Tipo de Servicio"
				scopes={[
					{ label: 'Servicios de Inspección', value: 'inspeccion' },
					{ label: 'Pruebas de Tanques y Tuberías', value: 'pruebas' },
				]}
				options={[
					{ 
						label: 'Buscar por empresa', 
						value: 'serviceReview.servicePlan.serviceInspector.service.proposal.inquiry.branchSpec.branch.company.name' 
					},
					{
						label: 'Buscar por nombre de instalación',
						value: 'serviceReview.servicePlan.serviceInspector.service.proposal.inquiry.branchSpec.branch.name'
					},
					{
						label: 'Buscar número de consecutivo propuesta',
						value: 'serviceReview.servicePlan.serviceInspector.service.proposal.sku_key'
					},
					{
						label: 'Buscar por nombre de inspector asignado',
						value: 'serviceReview.servicePlan.serviceInspector.user.name'
					},
					{
						label: 'Buscar por tipo de instalación',
						value: 'serviceReview.servicePlan.serviceInspector.service.proposal.inquiry.branchSpec.branch.instalationType.name'
					},
					{
						label: 'Buscar número de consecutivo del servicio',
						value: 'serviceReview.servicePlan.serviceInspector.service.id'
					},
				]}
			/>
			<Card>
				<CardBody>
					<ServiceReportsTable
						serviceReports={serviceReports}
						reload={() => setServiceReports(null)}
						pagination={pagination}
						setPagination={setPagination}
					/>
				</CardBody>
			</Card>
		</React.Fragment>
	)
}